import { Header } from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React from "react";
// import { Footer } from "./components/Footer";
// import { getIframeBaseUrl } from "./api";
import { Outlet } from "react-router-dom";
import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";

const App = () => (
  <div>
    <body>
      <Header style={{}} />
      <Outlet />
      {/* <iframe style={{ height: 'calc(100vh - 24px - 80px)', width: '100%' }} src={getIframeBaseUrl()}></iframe> */}
    </body>
  </div>
);

export default App;
