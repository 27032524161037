export const color = {
  // Main colors
  primary: "rgba(55, 81, 255, 0.9)",
  highlight: "#0A9EEC",

  secondary: "#0F1B4A",

  // Font colors
  black: "#000000",
  disabled: "#6d7070",
};
