import React from "react";
import logo from "../assets/logo.svg";
import menu from "../assets/menu.svg";
import closeIcon from "../assets/close.svg";

import styled from "styled-components";
import { color } from "../constants/color";
import { NavLink } from "react-router-dom";

export const Header = (props: any) => {
  const [MenuVisible, setMenuVisible] = React.useState(false);
  return (
    <>
      <HeaderContainer>
        <HeaderLeft>
          <a href="/">
            <img src={logo} alt=""></img>
          </a>
        </HeaderLeft>
        <HeaderRight>
          <StyledNavLink to="">Galactic Life</StyledNavLink>
        </HeaderRight>
        <HeaderRightSmall>
          <img
            src={menu}
            className="menu-button"
            alt=""
            onClick={() => {
              setMenuVisible(!MenuVisible);
            }}
          />
        </HeaderRightSmall>
      </HeaderContainer>
      {MenuVisible && (
        <Menu>
          <img
            src={closeIcon}
            alt=""
            className="close-button"
            onClick={() => setMenuVisible(false)}
          />
          <StyledNavLink onClick={() => setMenuVisible(false)} to="">
            Life Cover
          </StyledNavLink>
        </Menu>
      )}
    </>
  );
};

const HeaderLeft = styled.div`
  margin: 20px 0px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

const StyledNavLink = styled(NavLink)`
  @media (max-width: 768px) {
    display: block;
    padding: 10px 4px;
    font-size: 25px;
  }

  font-size: 18px;
  font-weight: 400;
  padding: 16.5% 4px;
  margin: auto 7px;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
  margin-right: 20px;

  :hover {
    color: ${color.highlight} !important;
  }
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }

  .profile-pic: {
    margin-right: 10px;
    height: 10px;
  }

  .active {
    box-shadow: 0 4px 0 0 ${color.highlight};
    color: ${color.black};
  }
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  overflow: overlay;
  box-shadow: 0px 4px 3px rgba(55, 81, 255, 0.09);
  -webkit-filter: drop-shadow(12px 12px 7px rgba(55, 81, 255, 0.09));
  padding-right: 25px;
  padding-left: 25px;
  vertical-align: middle;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const HeaderRightSmall = styled.div`
  display: none;

  .menu-button {
    width: 20px;
  }

  @media (max-width: 768px) {
    width: 20px;
    display: block;

    margin-top: auto;
    margin-right: 20px;
    margin-bottom: auto;
  }
`;

const Menu = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  .close-button {
    float: right;
    width: 20px;
    margin-right: 10px;
    display: block;
    cursor: pointer;
    margin-top: 10px;
  }
  position: absolute;
  width: 100%;
  background-color: white;
  padding-bottom: 10px;
`;
