import React from "react";
import { getIframeBaseUrl } from "../api";

export const GetInsurance = () => (
  <iframe
    style={{
      height: "calc(100vh - 83px)",
      width: "100%",
    }}
    src={getIframeBaseUrl()}
  ></iframe>
);
